import * as React from 'react'
import { connect } from 'react-redux'
import { IconButton, MenuItem, MenuList } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { makeStyles } from '@mui/styles'
import { Icon } from 'app/frontend/components/material/icon'
import { showModal } from 'app/frontend/components/material/modal/modal-actions'
import { AssessmentBuilderModalType } from 'app/frontend/pages/material/teach/assessment-builder/assessment-builder-modal-types'
import { PopperMenu } from 'app/frontend/components/popper-menu/popper-menu'
import { usePopperMenu } from 'app/frontend/components/popper-menu/use-popper-menu'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { sendEventTeachAssessment } from 'app/frontend/helpers/mixpanel/teach'
import { ParentEntityType } from 'app/typings/commons'
import {
  TEACH_COURSE_ASSESSMENT_KEBOB_EDIT_QUESTIONS,
  TEACH_SECTION_ASSESSMENT_KEBOB_EDIT_QUESTIONS,
} from 'app/frontend/data/mixpanel-events'
import { theme } from 'app/frontend/theme/theme'
import { DELETE_OBJECTIVE_MODAL } from './delete-objective-modal'

const t = tns('teach:learning_objective_menu')

type OwnProps = {
  assessmentId: string
  parentEntityType: string
  learningObjectiveId: string
  loDescription: string
  chapterName: string
  topicName: string
  pathLearningObjectives: Commons.IPathLearningObjectiveLeanEntity[]
}

type DispatchProps = {
  openQuestionsModal: () => void
  showDeleteObjectiveModal: () => void
}

type Props = OwnProps & DispatchProps

export const useStyles = makeStyles({
  menu: {
    display: 'block',
  },
  icon: {
    fontSize: '1.5rem',
    margin: 'auto 0',
    marginRight: '1rem',
    width: '1.5rem',
  },
})

export const _LearningObjectiveMenu: React.FunctionComponent<Props> = ({
  assessmentId,
  parentEntityType,
  loDescription,
  openQuestionsModal,
  showDeleteObjectiveModal,
}) => {
  const styles = useStyles()
  const onEditQuestions = () => {
    // send mixpanel event
    sendEventTeachAssessment(
      parentEntityType === ParentEntityType.Course
        ? TEACH_COURSE_ASSESSMENT_KEBOB_EDIT_QUESTIONS
        : TEACH_SECTION_ASSESSMENT_KEBOB_EDIT_QUESTIONS,
      assessmentId
    )
    // open questions modal
    openQuestionsModal()
  }

  const { open, anchorRef, handleToggle, handleClose, handleListKeyDown } = usePopperMenu()

  const menuStyles = {
    backgroundColor: theme.palette?.common.white,
  }

  return (
    <>
      <IconButton
        data-test="learning-objectives-menu"
        data-bi="learning-objectives-menu-button"
        ref={anchorRef}
        aria-label={`Options for learning objective, ${loDescription}`}
        aria-expanded={open ? 'true' : 'false'}
        aria-haspopup="true"
        onClick={handleToggle}
        sx={{ '&:focus': { outlineColor: '#064560 !important' } }}
      >
        <MoreVertIcon fontSize="small" htmlColor={theme.palette?.grey[900]} />
      </IconButton>
      <PopperMenu
        open={open}
        anchorRef={anchorRef}
        handleClose={handleClose}
        placement="bottom-end"
        styles={menuStyles}
      >
        <MenuList role="presentation" autoFocusItem={open} onKeyDown={handleListKeyDown}>
          <MenuItem data-bi="edit-questions" onClick={onEditQuestions}>
            <Icon name="icon-edit" className={styles.icon} />
            {t('edit_questions')}
          </MenuItem>
          <MenuItem data-bi="remove-objective" onClick={showDeleteObjectiveModal}>
            <Icon name="icon-clear" size="large" className={styles.icon} />
            {t('remove_objective')}
          </MenuItem>
        </MenuList>
      </PopperMenu>
    </>
  )
}

_LearningObjectiveMenu.displayName = 'LearningObjectiveMenu'

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps): DispatchProps => {
  const {
    assessmentId,
    learningObjectiveId,
    loDescription,
    chapterName,
    topicName,
    pathLearningObjectives,
  } = ownProps
  return {
    openQuestionsModal: () =>
      dispatch(
        showModal(AssessmentBuilderModalType.QuestionSelection, {
          assessmentId,
          learningObjectiveId,
          loDescription,
          chapterName,
          topicName,
          fromObjectivesModal: false,
        })
      ),
    showDeleteObjectiveModal: () =>
      dispatch(
        showModal(DELETE_OBJECTIVE_MODAL, {
          assessmentId,
          learningObjectiveId,
          pathLearningObjectives,
        })
      ),
  }
}

export const LearningObjectiveMenu = connect<{}, DispatchProps, OwnProps>(
  null,
  mapDispatchToProps
)(_LearningObjectiveMenu)
