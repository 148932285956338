/**
 * Compound Helper.
 */
import * as _ from 'lodash'
import { groupBy } from 'lodash'
import * as uuid from 'uuid'
import { ResultOverrideType } from 'app/typings/commons'

/**
 * Returns true if the atom contains a video.
 */
export const containsVideo = (atom: Content.IAtom): boolean =>
  atom.media.some(mediaAtom => mediaAtom.dataType === 'VIDEO_LOCATION')

/**
 * Returns true if the atom is instructional.
 */
export const isInstructional = (atom: Content.IAtom): boolean =>
  atom.dataType === 'INSTRUCTIONAL_ATOM'

export const getLearnosityType = (atom: Content.IAtom | GQL.Atom): Content.LearnosityType => {
  const data = atom.data as GQL.AtomLearnosityBlock
  if (data && data.content) {
    return data.content.type
  }
}

export const isLearnosityMultiAnswer = (atom: Content.IAtom | GQL.Atom): boolean => {
  const data = atom.data as GQL.AtomLearnosityBlock

  return (
    getLearnosityType(atom) === 'mcq' && data && data.content && !!data.content.multiple_responses
  )
}

export enum AlchemieQuestions {
  LEWIS_QUESTION = 'alchemie_lewis2_question',
  VSEPR_QUESTION = 'alchemie_vsepr2_question',
  HYBRIDIZATION_QUESTION = 'alchemie_hybridization2_question',
  EQUATION_QUESTION = 'alchemie_equation2_question',
  STOICHIOMETRY_QUESTION = 'alchemie_stoich_question',
}

export const isAlchemieQuestion = (atom: Content.IAtom | GQL.Atom): boolean => {
  const data = atom.data as GQL.AtomLearnosityBlock
  return [
    AlchemieQuestions.LEWIS_QUESTION,
    AlchemieQuestions.VSEPR_QUESTION,
    AlchemieQuestions.HYBRIDIZATION_QUESTION,
    AlchemieQuestions.EQUATION_QUESTION,
    AlchemieQuestions.STOICHIOMETRY_QUESTION,
  ].includes(data?.content?.['custom_type'])
}

export const doAnswersExistOnAtom = (atom: GQL.Atom) => {
  const dataType = atom.dataType
  let answersFound
  let data

  if (dataType === 'MULTIPLE_CHOICE_QUESTION') {
    data = atom.data as GQL.AtomMultiChoiceBlock
    answersFound = data.answers.find(answer => {
      return answer.answerType
    })
  } else {
    data = atom.data as GQL.AtomLearnosityBlock

    // The custom question types: alchemie_lewis2_question, alchemie_vsepr2_question,
    // alchemie_hybridization2_question, alchemie_equation2_question, and alchemie_stoich_question
    // did not originally have validation, so we need to check for the explanation field instead.
    answersFound = data?.content?.validation || (isAlchemieQuestion(atom) && data?.explanation)
  }
  return !!answersFound
}

export const isQuizContent = (compoundInstance: Commons.ICompoundInstance): boolean =>
  compoundInstance.source === 'QUIZ'

export const hasBeenAnswered = (compoundInstance: Commons.ICompoundInstance): boolean =>
  ['ANSWERED', 'DONE'].includes(compoundInstance.state)

export const isDone = (compoundInstance: Commons.ICompoundInstance): boolean =>
  compoundInstance.state === 'DONE'

export const isSkipped = (compoundInstance: Commons.ICompoundInstance): boolean =>
  compoundInstance.state === 'SKIPPED'

export const isAttempted = (compoundInstance: Commons.ICompoundInstance): boolean =>
  compoundInstance.state === 'ATTEMPTED'

export const isIgnored = (compoundInstance: Commons.ICompoundInstance): boolean =>
  compoundInstance.resultOverride === 'IGNORED'

/**
 * Small helper function for checking if a StudentEvent has any answer data.
 * @param data - The data as seen in StudentEvents
 * @returns {boolean} - False if the data and all answer fields are empty
 */
export const dataHasAnswer = data => {
  return (
    !_.isEmpty(data) &&
    !(_.isNil(data.answer) && _.isEmpty(data.mcAnswers) && _.isNil(data.mcAnswer))
  )
}

export const isCompoundAnswered = (compoundInstance: Commons.ICompoundInstance): boolean =>
  dataHasAnswer(compoundInstance.data)

export const isCompoundCorrect = (compoundInstance: Commons.ICompoundInstance): boolean => {
  const status = getStatus(compoundInstance)
  return [Status.OVERRIDE_CORRECT, Status.CORRECT].includes(status)
}

export const isCompoundPartiallyCredited = (
  compoundInstance: Commons.ICompoundInstance
): boolean => {
  const status = getStatus(compoundInstance)
  return [Status.OVERRIDE_PARTIAL_CREDIT].includes(status)
}

export const isCompoundIncorrect = (compoundInstance: Commons.ICompoundInstance): boolean => {
  const status = getStatus(compoundInstance)
  if (isQuizContent(compoundInstance)) {
    return [Status.OVERRIDE_INCORRECT, Status.SKIPPED, Status.INCORRECT].includes(status)
  } else {
    return [Status.OVERRIDE_INCORRECT, Status.INCORRECT].includes(status)
  }
}

export const isCompoundIgnored = (compoundInstance: Commons.ICompoundInstance): boolean => {
  const status = getStatus(compoundInstance)
  return status === Status.OVERRIDE_IGNORED
}

/**
 * Uses the points value of the assessment sequence to calculate the
 * score of the compound instance.
 *
 * We don't check if the assessment sequence has been removed because
 * compound instances of assessment sequences that have been removed
 * aren't returned to frackend
 * @param compound
 * @param pathSequence
 */
export const calculateCompoundInstanceScore = (
  compound: Commons.ICompoundInstance,
  pathSequence: Commons.IPathSequence
): {
  maxPoints: number
  score: number
} => {
  if (isCompoundIgnored(compound)) {
    return { maxPoints: 0, score: 0 }
  }

  // If path sequence is undefined or null, assume that the max possible points
  // for a compound instance is 1. Otherwise use the points value on the path
  // sequence
  const maxPoints = pathSequence && pathSequence.points >= 0 ? pathSequence.points : 1
  // If compound is correct, show score as maxPoints. Else check for partial credits
  // and show partial value (override-percentage * max-points). if not show 0

  const score = isCompoundCorrect(compound)
    ? maxPoints
    : isCompoundPartiallyCredited(compound)
    ? parseFloat((compound.overrideValue * maxPoints).toFixed(2))
    : 0
  return { maxPoints, score }
}

export const getParentSequenceVariationId = (compoundInstance: Commons.ICompoundInstance) => {
  return compoundInstance && compoundInstance.parentSequenceVariationId
}

export enum Status {
  CORRECT = 'CORRECT',
  INCORRECT = 'INCORRECT',
  NOT_ANSWERED = 'NOT_ANSWERED',
  SKIPPED = 'SKIPPED',
  ATTEMPTED = 'ATTEMPTED',
  OVERRIDE_CORRECT = 'OVERRIDE_CORRECT',
  OVERRIDE_INCORRECT = 'OVERRIDE_INCORRECT',
  OVERRIDE_PARTIAL_CREDIT = 'OVERRIDE_PARTIAL_CREDIT',
  OVERRIDE_IGNORED = 'OVERRIDE_IGNORED',
}

export const getStatus = (compoundInstance: Commons.ICompoundInstance): Status => {
  if (compoundInstance.resultOverride === 'OVERRIDDEN') {
    if (compoundInstance.overrideValue === CORRECT_VALUE) {
      return Status.OVERRIDE_CORRECT
    }
    if (compoundInstance.overrideValue === INCORRECT_VALUE) {
      return Status.OVERRIDE_INCORRECT
    }
    return Status.OVERRIDE_PARTIAL_CREDIT
  } else if (compoundInstance.resultOverride === 'IGNORED') {
    return Status.OVERRIDE_IGNORED
  } else if (isAttempted(compoundInstance)) {
    return Status.ATTEMPTED
  } else if (isSkipped(compoundInstance)) {
    return Status.SKIPPED
  } else if (!hasBeenAnswered(compoundInstance)) {
    return Status.NOT_ANSWERED
  } else if (compoundInstance.data && compoundInstance.data.correct) {
    return Status.CORRECT
  } else {
    return Status.INCORRECT
  }
}

export const seenBefore = (compoundInstance: Commons.ICompoundInstance): boolean => {
  const source = compoundInstance.source
  return (
    source === 'USER_STUCK' ||
    source === 'USER_STUCK_REPEAT' ||
    source === 'VIEW_RELATED_INSTRUCTION' ||
    source === 'VIEW_RELATED_INSTRUCTION_REPEAT' ||
    source === 'MORE_INSTRUCTION' ||
    source === 'MORE_INSTRUCTION_REPEAT'
  )
}

export const isMultipleAttemptedAnswers = (
  compoundInstance: Commons.ICompoundInstance
): boolean => {
  return (
    hasBeenAnswered(compoundInstance) &&
    compoundInstance.data &&
    compoundInstance.data.attempts &&
    compoundInstance.data.attempts.length > 1
  )
}

/**
 * @param atoms a list of atoms returned from the CMS v2 api
 *
 * returns a list of dummy compound states constructed with a CI containing just an id and parentSequenceVariationId and
 * an atom for each atom in the input. The parentSequenceVariationId is necessary for displaying the point value of each
 * compound instance
 */
export const convertAtomsToCompoundStates = (
  atoms: Content.IAtom[],
  pathSequences: Commons.IPathSequence[]
): Learn.IQuizPreviewState[] => {
  const pathSequencesBySequenceId = groupBy(pathSequences, 'sequenceId')
  return atoms.map(atom => {
    const sequenceId = atom.id.sequenceId
    const parentSequence = pathSequencesBySequenceId[sequenceId]
      ? pathSequencesBySequenceId[sequenceId].pop()
      : null
    const parentSequenceVariationId = parentSequence ? parentSequence.pathSequenceVariationId : null
    return { compoundInstance: { id: uuid.v4(), parentSequenceVariationId }, atom }
  })
}

/**
 * Compare two versions of a compound instance and
 * determine if changed from either of the following:
 * - not answered to answered
 * - not answered to attempted
 * - attempted to answered
 */
export const justAnsweredOrAttempted = (
  prevCompoundInstance: Commons.ICompoundInstance,
  currCompoundInstance: Commons.ICompoundInstance
): boolean => {
  const prevAnswered = hasBeenAnswered(prevCompoundInstance)
  const currAnswered = hasBeenAnswered(currCompoundInstance)
  const prevAttempted = isAttempted(prevCompoundInstance)
  const currAttempted = isAttempted(currCompoundInstance)

  return (!prevAnswered && currAnswered) || (!prevAttempted && currAttempted)
}

/**
 * Compare two versions of a compound instance and
 * determine if changed from answered to done
 */
export const justDone = (
  prevCompoundInstance: Commons.ICompoundInstance,
  currCompoundInstance: Commons.ICompoundInstance
): boolean => {
  const prevDone = isDone(prevCompoundInstance)
  const currDone = isDone(currCompoundInstance)

  return !prevDone && currDone
}

/**
 * For ADA - focus on the question feedback banner
 * (Bummer! You got this question wrong!)
 *
 * This is triggered after the user answers a
 * question.
 */
export const focusFeedbackOnInteraction = (compoundId: string): void => {
  const labelFeedback: HTMLSpanElement = document.querySelector(
    `[data-ada-label-feedback="${compoundId}"]`
  )
  if (labelFeedback) {
    labelFeedback.focus()
  }
}

// This method is used to identify questions which are not print-friendly.
// Desmos, Dataset questions
export const isAtomPrintFriendly = (atom: Content.IAtom): boolean => {
  const { data, dataType, media } = atom
  if (
    dataType === 'LEARNOSITY_GENERIC_QUESTION' &&
    (data as GQL.AtomLearnosityBlock).content.type === 'custom'
  ) {
    return false
  } else if (
    !_.isEmpty(media as Content.IMediaAtom[]) &&
    (media as Content.IMediaAtom[]).find(
      m => m.dataType === 'DATASET' || m.dataType === 'DESMOS_GRAPH'
    )
  ) {
    return false
  }
  return true
}

export const numInvalidPrintQuestions = (atoms: Content.IAtom[]): number =>
  atoms.filter(atom => !isAtomPrintFriendly(atom)).length

// This method is used to identify questions which are not ldb friendly.
// Dataset questions
export const isAtomLdbFriendly = (atom: Content.IAtom): boolean => {
  const { media } = atom
  if (
    !_.isEmpty(media as Content.IMediaAtom[]) &&
    (media as Content.IMediaAtom[]).find(m => m.dataType === 'DATASET')
  ) {
    return false
  }
  return true
}

export const numInvalidLdbQuestions = (atoms: Content.IAtom[]): number =>
  atoms.filter(atom => !isAtomLdbFriendly(atom)).length

export const getInvalidPrintQuestionsCount = (sequences: GQL.Sequence[]): number => {
  return sequences.reduce((sum, sequence) => sum + (sequence.isPrintFriendly ? 0 : 1), 0)
}

export const getInvalidLdbQuestionsCount = (sequences: GQL.Sequence[]): number => {
  return sequences.reduce((sum, sequence) => sum + (sequence.isLdbFriendly ? 0 : 1), 0)
}

export const CORRECT_VALUE = 1.0
export const INCORRECT_VALUE = 0.0

/**
 * Get marked correct override status
 *
 * @param override The result override action
 * @param overrideValue The override value for the action
 */
export const isMarkedCorrect = (override: ResultOverrideType, overrideValue?: number): boolean =>
  override === ResultOverrideType.OVERRIDDEN && overrideValue === CORRECT_VALUE

/**
 * Get marked in-correct override status
 *
 * @param override The result override action
 * @param overrideValue The override value for the action
 */
export const isMarkedIncorrect = (override: ResultOverrideType, overrideValue?: number): boolean =>
  override === ResultOverrideType.OVERRIDDEN && overrideValue === INCORRECT_VALUE
