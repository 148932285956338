import * as React from 'react'
import * as styles from './video-atom.css'
import { Icon } from 'app/frontend/components/material/icon'

interface ITranscriptProps {
  transcriptHtml: string
}

interface ITranscriptState {
  expanded: boolean
}

export default class Transcript extends React.Component<ITranscriptProps, ITranscriptState> {
  constructor(props) {
    super(props)

    this.state = {
      expanded: false,
    }
  }

  onExpandTranscriptClick = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    const iconName = this.state.expanded ? 'icon-expand-less' : 'icon-expand-more'
    return (
      <div>
        <button
          onClick={this.onExpandTranscriptClick}
          className={styles.transcriptButton}
          aria-label="toggle transcript visibility"
          aria-pressed={this.state.expanded}
          data-bi="transcript-toggle-button"
        >
          Transcript
          <Icon name={iconName} />
        </button>
        {this.state.expanded && (
          <div
            dangerouslySetInnerHTML={{ __html: this.props.transcriptHtml }}
            className={styles.transcript}
            aria-label="transcript content"
          />
        )}
      </div>
    )
  }
}
