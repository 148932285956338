import * as React from 'react'
import * as classNames from 'classnames'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { isMobile } from 'app/frontend/helpers/device'
import { downloadDataset } from './dataset-atom-helper'
import * as buttonStyles from 'app/frontend/components/material/button/button.css'
import * as styles from './dataset-atom.css'
import { Icon } from 'app/frontend/components/material/icon'

const t = tns('dataset')

interface IDatasetDownloadButton {
  csvString: string
}

export default class DatasetDownloadButton extends React.Component<IDatasetDownloadButton> {
  onDownloadDataset = () => {
    downloadDataset(this.props.csvString)
  }

  render(): JSX.Element {
    return (
      <button
        className={classNames(buttonStyles.nakedButton, styles.downloadButton)}
        onClick={this.onDownloadDataset}
        data-bi="dataset-download-button"
      >
        <Icon name="icon-download-dataset" className={styles.iconDatasetButton} />
        {isMobile() ? t('open') : t('download_csv')}
      </button>
    )
  }
}
