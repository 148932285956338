import * as React from 'react'
import { CardMaterial, CardTitleMaterial } from 'app/frontend/components/material/card/card'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { TextInputMaterial } from 'app/frontend/components/material/textinput/textinput'
import { reduxForm, Field, clearSubmitErrors, formValueSelector } from 'redux-form'
import LegalStuff from 'app/frontend/components/material/legal-stuff/legal-stuff'
import { submitForgotPasswordForm } from './forgot-password-actions'
import { validateEmail } from 'app/frontend/helpers/validation'
import { connect } from 'react-redux'
import { Icon } from 'app/frontend/components/material/icon'
import Circle from 'app/frontend/components/material/circle/circle'
import SignedOutLogo from '../signed-out-logo'
import { MainMaterial } from 'app/frontend/layout/material/main'
import { Link } from 'react-router-dom'

import * as styles from './forgot-password.css'
import { tns } from 'app/frontend/helpers/translations/i18n'

const t = tns('forgot_password')

interface ForgotPasswordProps {
  handleSubmit: () => void
  valid?: boolean
  submitting?: boolean
  confirmationScreen?: boolean
  email: string
}

const EmailField = field => (
  <TextInputMaterial
    name={field.name}
    label={field.label}
    className={styles.emailInput}
    autoComplete="email"
    {...field.input}
  />
)

interface ResetPasswordForm {
  handleSubmit: () => void
  valid?: boolean
  submitting?: boolean
}

const ResetPasswordForm: React.FunctionComponent<ResetPasswordForm> = ({
  handleSubmit,
  valid,
  submitting,
}) => (
  <div>
    <CardTitleMaterial className={styles.cardTitle} theme={{ title: styles.cardTitleHeader }}>
      {t('reset_password')}
    </CardTitleMaterial>
    <p className={styles.text}>{t('enter_email')}</p>
    <form onSubmit={!submitting ? handleSubmit : undefined}>
      <Field name="email" label={t('email_field_label')} component={EmailField} />
      <ButtonMaterial
        name="reset-password"
        label={t('send_password_reset_link')}
        data-bi="reset-password-button"
        onClick={handleSubmit}
        className={styles.button}
        disabled={!valid || submitting}
      />
    </form>
  </div>
)

ResetPasswordForm.displayName = 'ResetPasswordForm'

interface EmailSentConfirmation {
  email: string
  handleSubmit: () => void
}

const EmailSentConfirmation: React.FunctionComponent<EmailSentConfirmation> = ({
  email,
  handleSubmit,
}) => (
  <div className={styles.confirmationScreen}>
    <Circle className={styles.circle}>
      <Icon name="icon-email-check" className={styles.emailIcon} />
    </Circle>
    <p className={styles.emailHeader}>{t('email_header_confirm')}</p>
    <p className={styles.text}>
      {t('email_sent', {
        email,
      })}
    </p>
    <p className={styles.text}>
      {t('didnt_get_email')}
      <button className={styles.textLink} onClick={handleSubmit} data-bi="forgot-password-send-another-email-button">
        {t('send_another_email')}
      </button>
    </p>
  </div>
)

EmailSentConfirmation.displayName = 'EmailSentConfirmation'

const FORGOT_PASSWORD_FORM = 'forgot-password'
export const ForgotPassword: React.FunctionComponent<ForgotPasswordProps> = ({
  handleSubmit,
  valid,
  submitting,
  confirmationScreen,
  email,
}): JSX.Element => (
  <div className={styles.container}>
    <SignedOutLogo />
    <Link to="/login" data-bi="login-link" className={styles.signinLink}>
      {t('caps_sign_in')}
    </Link>
    <MainMaterial>
      <CardMaterial className={styles.card}>
        {!confirmationScreen ? (
          <ResetPasswordForm handleSubmit={handleSubmit} valid={valid} submitting={submitting} />
        ) : (
          <EmailSentConfirmation handleSubmit={handleSubmit} email={email} />
        )}
      </CardMaterial>
    </MainMaterial>
    <LegalStuff />
  </div>
)

const validate = (values, _dispatch, _props) => ({
  email: validateEmail(values.email),
})

const mapStateToProps = state => ({
  confirmationScreen: state.forgotPassword.confirmationScreen,
  email: formValueSelector(FORGOT_PASSWORD_FORM)(state, 'email'),
})

let WrappedForgotPassword = reduxForm({
  form: FORGOT_PASSWORD_FORM,
  validate,
})(ForgotPassword)
WrappedForgotPassword = connect(mapStateToProps, {
  onSubmit: submitForgotPasswordForm,
  clearSubmitErrors,
})(WrappedForgotPassword)
const ForgotPasswordForm = WrappedForgotPassword
ForgotPasswordForm.displayName = 'ForgotPasswordForm'

export default ForgotPasswordForm
